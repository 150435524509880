import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CardNews from "../components/cardNews"
import CardFeatured from "../components/cardFeatured"

const IndexPage = ({ data }) => {
  const { allArticlesQuery, globalYaml, lastArticlesFeatured } = data // data.markdownRemark holds our post data
  let firstFeaturedHasBeenRemoved = false
  return (
    <Layout>
      <SEO title="Home" />
      <div className="container">
        {lastArticlesFeatured.nodes.map((articleFeatured, i) => {
          return <CardFeatured key={i} news={articleFeatured} />
        })}
      </div>
      <div className="columns is-centered is-marginless">
        <div className="column is-8">
          <div className="container">
            <h1 className="title is-4">{globalYaml.index.title}</h1>

            <div className="columns is-multiline is-centered">
              {allArticlesQuery.nodes.map((post, i) => {
                // hide first featured post (because its already displayed in cardFeature)
                if (firstFeaturedHasBeenRemoved === false) {
                  if (post.featured === true) {
                    firstFeaturedHasBeenRemoved = true
                    return false
                  }
                }
                // let isFeatured = post.featured ? "is-12" : "is-6"
                return (
                  <div key={i} className={`column is-6 `}>
                    <CardNews
                      news={post}
                      displayCountry={true}
                      displayDate={true}
                      displaySneakPeek={50} // false or number of char to display
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const homeQuery = graphql`
  query homePageQuery {
    globalYaml: globalYaml {
      index {
        title
      }
    }
    lastArticlesFeatured: allStrapiPosts(
      filter: { featured: { eq: true } }
      limit: 1
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...StrapiPosts
      }
    }
    allArticlesQuery: allStrapiPosts(
      filter: { homepage_display: { eq: true } }
      limit: 16
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        bait
        title
        emoji
        featured
        content
        quick_post
        music_title
        album_title
        producer
        director_realisateur
        createdAt
        createdAtReadable: createdAt(
          formatString: "DD MMMM YYYY à HH:mm"
          locale: "fr_FR"
        )
        artists {
          Name
          country
        }
        countries {
          code
        }
        type {
          name
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
